import { Spacer } from 'components/layouts/Spacer';
import { Heading, Paragraph } from 'components/typography';
import { Hero as HeroType } from 'generated/graphql';
import { useGetOrCreateDaoSession } from 'hooks/useGetOrCreateDaoSession';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Base64 from 'utils/Base64';
import { isExternalLink, isInternalLink } from 'utils/link';
import * as S from './Hero.styles';

export const Hero = ({ module }: { module: HeroType }) => {
  const { preHeader, header, buttonLabel, projectIdOrButtonLink, background, subHeader } = module;
  const router = useRouter();
  const session = useGetOrCreateDaoSession();
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, []);

  const handleButton = () => {
    if (isInternalLink(projectIdOrButtonLink)) {
      router.push(projectIdOrButtonLink);
      return;
    }
    if (isExternalLink(projectIdOrButtonLink)) {
      window.location.href = projectIdOrButtonLink;
      return;
    }

    session.loadAndJoinSession(Base64.toNumber(projectIdOrButtonLink).toString());
  };

  const isSmallScreen = screenWidth < 450;

  return (
    <S.BannerContainer>
      <S.HeroImage src={background?.url ?? ''} alt="Preview of HiberWorld" fill priority />
      <S.Gradient />
      <S.ContentContainer>
        <Heading size={isSmallScreen ? 'h3' : 'h1'} as="h2">
          {preHeader}
        </Heading>
        <Heading size={isSmallScreen ? 'h2' : 'h1'} as="h1">
          {header}
        </Heading>
        <Spacer height={isSmallScreen ? '0.25rem' : '1rem'} />
        <Paragraph size={isSmallScreen ? 'medium' : 'large'} maxWidth="340px">
          {subHeader}
        </Paragraph>
        <Spacer height="1rem" />

        <S.HeroButton
          textStyle="large"
          className="red"
          busy={session.loading}
          disabled={session.loading}
          onClick={handleButton}
          text={buttonLabel}
        />
      </S.ContentContainer>
    </S.BannerContainer>
  );
};

export default Hero;
