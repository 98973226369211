import styled from 'styled-components';

export const Sessions = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
`;

export const Header = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSize.title};
  margin: 0;

  @media screen and (max-width: 900px) {
    font-size: ${({ theme }) => theme.fontSize.titleSmaller};
  }
`;
