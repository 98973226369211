import { ErrorMessage } from 'components/common/ErrorMessage';
import { ProjectCard } from 'components/cards/ProjectCard';
import { RecommendationSort, SortBy, useRecommendationsQuery } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { ENGINE_TRACK_URL } from 'hooks/useDive/useDive.constants';
import { useRouter } from 'next/router';
import Base64 from 'utils/Base64';
import { PageModuleTypeProp } from '../PageModule.types';
import { FeedHeading } from '../shared/FeedHeading';
import { ProjectsGrid } from './ProjectFeed.styles';
import { ProjectFeedLoader } from './ProjectFeedLoader';

export const ProjectFeedRecommendedModule = ({
  module,
  name,
}: {
  module: PageModuleTypeProp<'ProjectFeedRegular'>;
  name: string;
}) => {
  const { trackUserInteract } = useDive();
  const router = useRouter();

  const { loading, data, error } = useRecommendationsQuery({
    variables: {
      quantity: module.quantity,
      sort: module.sortBy === SortBy.Random ? RecommendationSort.Random : RecommendationSort.Score,
    },
  });

  const trackProjectCardClick = (projectId: string, destination: string) => {
    trackUserInteract(destination, router.asPath, name, 'feed', projectId);
  };

  if (loading) {
    return <ProjectFeedLoader quantity={module.quantity} layout={module.layout} />;
  }

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>;
  }
  const projects = data?.recommendations.items ?? [];

  const slug = module.page?.slug;
  const viewMoreLink = slug ? `/feed/${slug}/${module.sortBy.toLowerCase()}` : undefined;

  if (projects.length > 0) {
    return (
      <>
        <FeedHeading heading={module.title} viewMoreLink={viewMoreLink} />
        <ProjectsGrid layout={module.layout}>
          {projects.map(project => (
            <ProjectCard
              project={project}
              key={project.id}
              playOnClick={module.directPlayOnClick}
              showAuthor={Boolean(module.showAuthor)}
              onAuthorClick={() => trackProjectCardClick(project.id, `/user/${project.author.username}`)}
              onProjectClick={() =>
                trackProjectCardClick(
                  project.id,
                  module.directPlayOnClick ? ENGINE_TRACK_URL : `/world/${Base64.fromNumber(Number(project.id))}`
                )
              }
            />
          ))}
        </ProjectsGrid>
      </>
    );
  }

  return null;
};

export default ProjectFeedRecommendedModule;
