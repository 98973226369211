import { BannerLayout } from 'generated/graphql';
import Image from 'next/image';
import styled, { css } from 'styled-components';

type BannerStyleProps = {
  layout: BannerLayout;
};

export const Wrapper = styled.div<BannerStyleProps>`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: ${({ layout }) => (layout === BannerLayout.Large ? '400px' : 'auto')};
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
`;
export const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
`;

export const ImageOverlay = styled.div`
  padding: 1rem;
  z-index: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, rgb(0, 0, 0, 0.4), rgb(255, 255, 255, 0));
`;

export const BackgroundImage = styled(Image)`
  object-fit: cover;
  object-position: center;
`;

const smallLayout = css`
  width: 100%;
  display: flex;
  align-items: flex-start;
  max-width: unset;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoint.mediumSmall}) {
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
  }
`;

export const InformationContainer = styled.div<BannerStyleProps>`
  margin: 0 2rem;
  position: relative;
  padding: 1.5rem 0;

  z-index: 2;
  max-width: 450px;

  ${({ layout }) => layout === BannerLayout.Small && smallLayout}

  @media (min-width: ${({ theme }) => theme.breakpoint.large}) {
    margin: 0 4rem;
  }
`;
