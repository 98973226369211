import { Button } from 'components/common/buttons/Button';
import { FlexBox } from 'components/layouts/FlexBox';
import { Heading, Paragraph } from 'components/typography';
import { useDive } from 'hooks/useDive';
import { useRouter } from 'next/router';
import { Modal } from '../Modal';
import { InformationalModalProps } from '../Modal.types';

export const InformationModal = ({
  close,
  text,
  headline,
  onConfirm,
  confirmButtonText,
  children,
  trackButtonName,
}: InformationalModalProps) => {
  const dive = useDive();
  const router = useRouter();
  const confirm = () => {
    dive.trackUserInteract(router.asPath, '', 'information_modal_accept', 'button', trackButtonName ?? headline ?? '');
    onConfirm?.();
    close?.();
  };
  return (
    <Modal
      onClose={() => {
        dive.trackUserInteract(
          router.asPath,
          '',
          'information_modal_close',
          'button',
          trackButtonName ?? headline ?? ''
        );
      }}>
      {Boolean(headline) && (
        <Heading size="h2" as="h1" marginBottom="2rem" textAlign="center">
          {headline}
        </Heading>
      )}
      {Boolean(text) && (
        <Paragraph size="medium" marginBottom="1rem">
          {text}
        </Paragraph>
      )}
      {Boolean(children) && children}
      <FlexBox justifyContent="center" marginTop="2rem">
        <Button variety="primary" size="medium" text={confirmButtonText ?? 'OK'} onClick={confirm} />
      </FlexBox>
    </Modal>
  );
};
