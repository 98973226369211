import * as S from './SessionCard.styles';

export const OnlineIndicator = ({ color }) => {
  return (
    <S.Container data-testid="online-indicator">
      <S.OnlineDot color={color} />
      <S.OnlineRing size={8} delay={0} color={color} />
      <S.OnlineRing size={8} delay={0.6} color={color} />
    </S.Container>
  );
};
