import { FeedCardLayout } from 'generated/graphql';
import styled from 'styled-components';

export const ProjectsGrid = styled.div<{ layout?: FeedCardLayout }>`
  display: grid;
  gap: 0.625rem;

  grid-template-columns: repeat(2, calc(50% - 0.625rem / 2));

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.breakMobile}) {
    grid-template-columns: repeat(3, calc(33.33% - (0.625rem - 0.625rem / 3)));
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.mediumLarge}) {
    gap: 1rem;
    grid-template-columns: ${({ layout }) =>
      layout === FeedCardLayout.Large ? 'repeat(2, calc(50% - 1rem / 2))' : 'repeat(4, calc(25% - 0.75rem))'};
  }
`;
