import styled from 'styled-components';

export const UserCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 1rem;
  @media screen and (max-width: ${props => props.theme.sass.break_medium}) {
    margin-bottom: 1rem;
  }
`;

export default UserCardContainer;
