import { FlexBox } from 'components/layouts/FlexBox';
import { LoadingCard, TextPlaceholder } from 'components/common/SkeletonLoader';
import { useTheme } from 'styled-components';
import { WrapperFlexBox } from './NewsFeed.styles';

export const NewsFeedLoader = () => {
  const theme = useTheme();

  return (
    <WrapperFlexBox aria-label="loading..." gap="1rem" width="100%" margin="0 0 4rem 0">
      <WrapperFlexBox width="50%">
        <LoadingCard width="100%" height="320px" borderRadius={8} />
      </WrapperFlexBox>
      <WrapperFlexBox width="50%">
        <FlexBox direction="column" width="100%" gap="1rem" justifyContent="center">
          <TextPlaceholder width="80%" height={theme.typography.header.h2.lineHeight} marginBottom="1.5rem" />
          <TextPlaceholder width="60%" height={theme.typography.body.small.lineHeight} />
          <TextPlaceholder width="70%" height={theme.typography.body.small.lineHeight} />
          <TextPlaceholder width="55%" height={theme.typography.body.small.lineHeight} />
          <TextPlaceholder width="25%" height="3rem" marginTop="1.5rem" />
        </FlexBox>
      </WrapperFlexBox>
    </WrapperFlexBox>
  );
};
