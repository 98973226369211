import { FlexBox } from 'components/layouts/FlexBox';
import { LoadingCard } from 'components/common/SkeletonLoader';
import { Spacer } from 'components/layouts/Spacer';

export const SessionCardLoader = () => {
  return (
    <FlexBox>
      <FlexBox direction="column">
        <LoadingCard height={256} width={196} borderRadius={8} />
        <Spacer width={8} height={10} />
        <LoadingCard width={120} height={16} borderRadius={8} />
        <Spacer width={16} height={10} />
      </FlexBox>
      <Spacer width={16} height={0} />
    </FlexBox>
  );
};
