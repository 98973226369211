import Image from 'next/image';
import styled from 'styled-components';

export const ChallengeWrapper = styled.div`
  overflow: hidden;
  position: relative;
  height: 400px;
  width: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.medium}) {
    padding: 0 2rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.large}) {
    padding: 0 8rem;
  }
`;

export const BackgroundImage = styled(Image)`
  object-fit: cover;
  object-position: center;
`;

export const InformationContainer = styled.div`
  position: absolute;
  max-width: 320px;

  @media (min-width: ${({ theme }) => theme.breakpoint.small}) {
    max-width: 450px;
  }
`;
