import Button from 'components/legacy/Button';
import { Spinner } from 'components/common/spinners/Spinner';
import { Heading, Paragraph } from 'components/typography';
import { useLatestHiberChallengeQuery } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { useGetOrCreateDaoSession } from 'hooks/useGetOrCreateDaoSession';
import { useRouter } from 'next/router';
import { isExternalLink, isInternalLink } from 'utils/link';
import { PageModuleTypeProp } from '../PageModule.types';
import * as S from './ChallengeBanner.styles';

export const ChallengeBanner = ({ module, name }: { module: PageModuleTypeProp<'ChallengeBanner'>; name: string }) => {
  const router = useRouter();
  const challenge = useLatestHiberChallengeQuery();
  const showButton = module?.buttonText && module?.projectIdOrLink;
  const session = useGetOrCreateDaoSession();
  const { trackUserInteract } = useDive();

  if (challenge.loading) {
    return <Spinner />;
  }

  if (!challenge.data?.latestHiberChallenge) {
    return null;
  }

  const { latestHiberChallenge } = challenge.data;

  const navigate = () => {
    const { projectIdOrLink } = module;

    if (!projectIdOrLink) {
      return;
    }
    trackUserInteract(projectIdOrLink, router.asPath, name, 'banner', latestHiberChallenge.title);

    if (isInternalLink(projectIdOrLink)) {
      router.push(projectIdOrLink);
      return;
    }
    if (isExternalLink(projectIdOrLink)) {
      window.open(projectIdOrLink);
      return;
    }
    session.loadAndJoinSession(projectIdOrLink);
  };

  return (
    <S.ChallengeWrapper>
      <S.BackgroundImage src={latestHiberChallenge.thumbnail.url} fill alt="" />
      <S.InformationContainer>
        <Heading size="h2">{latestHiberChallenge.title}</Heading>
        <div style={{ maxWidth: '400px' }}>
          <Paragraph size="small">{latestHiberChallenge.description}</Paragraph>
        </div>
        {showButton && <Button className="red" text={module.buttonText ?? ''} onClick={navigate} />}
      </S.InformationContainer>
    </S.ChallengeWrapper>
  );
};

export default ChallengeBanner;
