import styled from 'styled-components';

export const UserFeedSearchWrapper = styled.div`
  text-align: left;
  margin: 0 auto;
  width: 100%;
  padding: 0 6px;
`;

export const UserFeedLayout = styled.div`
  display: grid;
  grid-template-columns: auto;

  gap: 0.625rem;
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.small}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.tabletPortrait}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.breakMobile}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.mediumLarge}) {
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.large}) {
    gap: 1rem;
    grid-template-columns: repeat(6, 1fr);
  }
`;

export const UserFeedWrapper = styled.div`
  width: 100%;
  max-width: 1420px;
  .desc-wrapper {
    display: flex;
    flex-direction: row;
    margin: 3rem 0 5rem 0;
    .text {
      width: 42%;
      margin-right: 8%;
      h1 {
        font-size: 3rem;
        font-weight: 800;
      }
      @media screen and (max-width: 1024px) {
        margin-left: 4%;
        width: 42%;
        h1 {
          font-size: 2.1rem;
        }
      }
    }
    .image {
      display: flex;
      /* justify-content: flex-start;
      align-items: center; */
      width: 50%;
      min-width: 450px;
      img {
        width: 70%;
        min-width: 450px;
      }
      @media screen and (max-width: 850px) {
        min-width: 350px;
        img {
          min-width: 350px;
        }
      }
    }
    @media screen and (max-width: 700px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 2rem 0 2rem 0;
      .image {
        width: 60%;
        min-width: 300px;
        img {
          width: 100%;
          min-width: initial;
        }
      }
      .text {
        margin-right: 0;
        margin-top: 0rem;
        width: 60%;
        min-width: 300px;
      }
    }
  }
  .friend-request-container {
    @media (max-width: 550px) {
      display: flex;
      justify-content: center;
    }
  }
`;
