import { FlexBox } from 'components/layouts/FlexBox';
import { ProjectCardLoader } from 'components/cards/ProjectCard/ProjectCard.loader';
import { TextPlaceholder } from 'components/common/SkeletonLoader';
import { FeedCardLayout } from 'generated/graphql';
import { ProjectsGrid } from './ProjectFeed.styles';

interface ProjectFeedItemLoaderProps {
  quantity: number;
  layout?: FeedCardLayout;
}
export const ProjectFeedLoader = ({ quantity, layout }: ProjectFeedItemLoaderProps) => (
  <FlexBox margin="1rem 0" direction="column" aria-label="loading...">
    <TextPlaceholder width="80%" height="36px" marginBottom="1rem" />
    <ProjectsGrid layout={layout}>
      {Array.from({ length: quantity }, (_, i) => (
        <ProjectCardLoader key={`${Math.random()}${i}`} />
      ))}
    </ProjectsGrid>
  </FlexBox>
);
