import { Spacer } from 'components/layouts/Spacer';
import { Spinner } from 'components/common/spinners/Spinner';
import { Heading } from 'components/typography';
import { UserCard } from 'components/legacy/UserCardContainer';
import { UserFeedLayout } from 'views/feeds/userFeed/UserFeed.styles';
import { useFetchUsersQuery } from 'generated/graphql';
import { PageModuleTypeProp } from '../PageModule.types';

export const UserFeed = ({ module }: { module: PageModuleTypeProp<'UserFeedRegular'> }) => {
  const fetchUsers = useFetchUsersQuery({ variables: { first: 6, filter: 'per' } });

  if (fetchUsers.loading) {
    return <Spinner />;
  }

  if (!fetchUsers.data?.users?.edges) {
    return null;
  }

  const { users } = fetchUsers.data;
  return (
    <>
      <Heading size="h2" as="h2">
        {module.title}
      </Heading>
      <Spacer height={16} />
      <UserFeedLayout>
        {users.edges.map(user => (
          <UserCard user={user.node} key={user.node.id} />
        ))}
      </UserFeedLayout>
    </>
  );
};

export default UserFeed;
