import { Button } from 'components/common/buttons/Button';
import { FlexBox } from 'components/layouts/FlexBox';
import { Spacer } from 'components/layouts/Spacer';
import { Heading } from 'components/typography';
import Link from 'next/link';

export const FeedHeading = ({ heading, viewMoreLink }: { heading: string; viewMoreLink?: string }) => {
  return (
    <>
      <FlexBox justifyContent="space-between" alignItems="center">
        <Heading size="h4" as="h4">
          {heading}
        </Heading>
        {viewMoreLink && (
          <Link href={viewMoreLink} prefetch={false}>
            <Button text="View all" variety="text" size="medium" />
          </Link>
        )}
      </FlexBox>
      <Spacer height={12} />
    </>
  );
};
