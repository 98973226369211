import React from 'react';
import { OnlineIndicator } from './OnlineIndicator';
import * as S from './SessionCard.styles';
import { Label } from 'components/typography';
import { CircularImage } from 'components/common/images/CircularImage';
import { Link } from 'components/common/Link';
import { FlexBox } from 'components/layouts/FlexBox';
import { ProjectActiveFriend } from 'hooks/useActiveFriends';

interface OnlinePlayersProps {
  label: string;
  friends?: ProjectActiveFriend[];
  color?: string;
}

export const OnlinePlayers: React.FC<OnlinePlayersProps> = ({ label, color, friends = [] }) => {
  return (
    <S.Online>
      <FlexBox gap="0.5rem" alignItems="center">
        <OnlineIndicator color={color} />
        <Label size="large">{label}</Label>
        {friends.map(friend => (
          <Link href={`/user/${friend.username}`} key={`playing-friend-${friend.username}`}>
            <CircularImage size={25} key={friend.username} src={friend.pictureUrl} alt={friend.username} />
          </Link>
        ))}
      </FlexBox>
    </S.Online>
  );
};
