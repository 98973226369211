import { FlexBox } from 'components/layouts/FlexBox';
import Image from 'next/image';
import styled from 'styled-components';

export const NewsImage = styled(Image)`
  overflow: hidden;
  object-fit: contain;
  object-position: top;
  width: 100%;
  height: auto;
  @media (min-width: ${({ theme }) => theme.breakpoint.medium}) {
    height: 100%;
    width: auto;
    object-fit: cover;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.large}) {
  }
`;

export const ImageContainer = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
`;

export const NewsContainer = styled.div`
  background-color: ${({ theme }) => theme.primaryColor.veryDark};
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 0.5rem;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoint.medium}) {
    grid-template-columns: 1fr;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.mediumLarge}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const TextContainer = styled(FlexBox)`
  padding: 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoint.medium}) {
    padding: 1rem 4rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.mediumLarge}) {
  }
`;

export const WrapperFlexBox = styled(FlexBox)`
  @media (max-width: ${({ theme }) => theme.breakpoint.medium}) {
    flex-direction: column;
    width: 100%;
  }
`;
